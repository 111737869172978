<template>
    <section class="business client-application">
      <div class="client-application__header">
        <a href="/" class="client-application__logo">
          <icon icon="university-logo" width="75" height="75" />
        </a>
      </div>

      <picture class="client-application__banner d-none d-lg-block">
        <source
            srcset="/local/templates/hse/images/client-application/business.webp 1x, /local/templates/hse/images/client-application/business@2x.webp 2x"
            type="image/webp"
        >
        <source
            srcset="/local/templates/hse/images/client-application/business.jpg 1x, /local/templates/hse/images/client-application/business@2x.jpg 2x"
            type="image/jpeg"
        >
        <img src="/local/templates/hse/images/client-application/business.jpg" alt="Бизнес-консультирование">
      </picture>

      <div class="client-application__content">
        <h1 class="h1">Заявка</h1>
        <h2 class="client-application__subtitle">Психоанализ и&nbsp;психологическое бизнес-консультирование</h2>

        <FormClientApplication
          :data="form"
          :isSent="isSent"
          need-personal-agree
          class="client-application__form"
          @submit="submitForm"
        />
      </div>

      <Loader v-if="showLoader" />
    </section>
</template>

<script>
import { api } from '../../../api';
import icon from '../icon';
import Loader from '../Loader';
import { mapDataToSubmitData } from './utils'
import FormClientApplication from '../formClientApplication';

export default {
  name: "business",
  components: {
    icon,
    Loader,
    FormClientApplication
  },
  data () {
    return {
      showLoader: false,
      isSent: false,
      form: {
        mainField: [
          {
            type: 'input',
            name: 'fio',
            attributes: {
              label: 'ФИО*',
              placeholder: 'ФИО',
              type: 'text'
            },
            validators: {
              required: true
            }
          },
          {
            type: 'input',
            name: 'age',
            attributes: {
              label: 'Возраст*',
              placeholder: 'Лет',
              type: 'number'
            },
            validators: {
              required: true
            }
          },
          {
            type: 'input',
            name: 'city',
            attributes: {
              label: 'Город*',
              placeholder: 'Город'
            },
            validators: {
              required: true
            }
          },
          {
            type: 'input',
            name: 'phone',
            attributes: {
              label: 'Телефон*',
              placeholder: '+7 000 000 00 00',
              mask: "+7 (###) ### ## ##"
            },
            validators: {
              required: true
            }
          },
          {
            type: 'input',
            name: 'email',
            attributes: {
              label: 'E-mail*',
              placeholder: 'email@email.ru'
            },
            validators: {
              required: true,
              email: true
            }
          },
          {
            type: 'input',
            name: 'workPlace',
            attributes: {
              label: 'Место работы*',
              placeholder: 'Место работы'
            },
            validators: {
              required: true
            }
          },
          {
            type: 'input',
            name: 'position',
            attributes: {
              label: 'Должность*',
              placeholder: 'Должность'
            },
            validators: {
              required: true
            }
          },
          {
            type: 'group-checkbox',
            name: 'experienceWorkingWith',
            attributes: {
              label: 'Был ли опыт работы с коучем / психологом',
              isOnlyOne: true
            },
            validators: {},
            options: [
              {
                value: true,
                label: 'Да'
              },
              {
                value: false,
                label: 'Нет'
              }
            ]
          }
        ],
        request: {
          type: 'group-checkbox',
          name: 'request',
          attributes: {
            label: 'Запрос*',
            other: {
              show: true,
              label: 'Другое',
              placeholder: 'Другое'
            }
          },
          validators: {
            required: true
          },
          options: [
            {
              value: 'Профессиональное и карьерное развитие',
              label: 'Профессиональное и карьерное развитие'
            },
            {
              value: 'Поиск новой профессии, карьерное консультирование',
              label: 'Поиск новой профессии, карьерное консультирование'
            },
            {
              value: 'Коммуникации с коллегами, взаимодействие с руководителем',
              label: 'Коммуникации с коллегами, взаимодействие с руководителем'
            },
            {
              value: 'Формирование команды',
              label: 'Формирование команды'
            },
            {
              value: 'Управление конфликтными ситуациями',
              label: 'Управление конфликтными ситуациями'
            },
            {
              value: 'Адаптация на новом месте, новая профессиональная роль',
              label: 'Адаптация на новом месте, новая профессиональная роль'
            },
            {
              value: 'Создание и развитие своего бизнеса',
              label: 'Создание и развитие своего бизнеса'
            },
            {
              value: 'Прокрастинация, выгорание, утрата интереса к своей работе',
              label: 'Прокрастинация, выгорание, утрата интереса к своей работе'
            },
            {
              value: 'Личный вопрос',
              label: 'Личный вопрос'
            }
          ]
        },
        preferredWorkFormat: {
          type: 'group-checkbox',
          name: 'preferredWorkFormat',
          attributes: {
            label: 'Предпочтительный формат работы*',
            isOnlyOne: true
          },
          validators: {
            required: true
          },
          options: [
            {
              value: 'Очно (г. Москва)',
              label: 'Очно (г. Москва)'
            },
            {
              value: 'Онлайн',
              label: 'Онлайн'
            }
          ]
        },
        preferredConsultationTime: {
          type: 'time-week',
          name: 'preferredConsultationTime',
          attributes: {
            label: 'Предпочтительное время консультаций*',
            stringResult: true
          },
          validators: {
            required: true
          }
        },
        whereFound: {
          type: 'group-checkbox',
          name: 'whereFound',
          attributes: {
            label: 'Как вы узнали о проекте?',
            other: {
              show: true,
              label: 'Другое',
              placeholder: 'Другое'
            }
          },
          validators: {},
          options: [
            {
              value: 'Поиск в Интернете',
              label: 'Поиск в Интернете'
            },
            {
              value: 'Сайт Высшей Школы Экономики',
              label: 'Сайт Высшей Школы Экономики'
            },
            {
              value: 'Рекомендация знакомых',
              label: 'Рекомендация знакомых'
            }
          ]
        }
      }
    }
  },
  methods: {
    submitForm (data) {
      this.showLoader = true
      const submitData = mapDataToSubmitData(data)

      api.sendFormAppBusiness(submitData)
        .then((result) => {
          if (result?.data?.success) {
            this.isSent = true;
            this.$emit('update-clients');
          }

          this.showLoader = false
        })
        .catch(() => {
          this.showLoader = false
        })
    }
  }
}
</script>
